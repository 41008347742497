<template>
  <div class="setzhongzi">
    <van-nav-bar
      title="种子兑换等级设置"
      left-arrow
      @click-left="onClickLeft"
    />

    <van-form @submit="onSubmit">
      <van-field
        v-model="levelName"
        name="level"
        label="兑换等级"
        placeholder="选择等级"
        readonly
        is-link
        @click="showLevelList"
        :rules="[{ required: true, message: '请选择等级' }]"
      />
      <van-field
        v-model="from.shichang"
        name="shichang"
        label="兑换时长"
        placeholder="兑换时长单位天"
        :rules="[{ required: true, message: '请选择兑换时长' }]"
      >
        <template #right-icon>
          天
        </template>
      </van-field>
      <van-field
        v-model="from.zhongzi"
        name="zhognzi"
        label="所需种子"
        placeholder="填写种子数量"
        :rules="[{ required: true, message: '请填写所需种子数量' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="showLevel" position="bottom">
      <van-picker
        title="选择等级"
        show-toolbar
        :columns="levelArr"
        :default-index="levelIndex"
        value-key="levelname"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    

  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "SetZhongzi",
  data() {
    return {
      info: {},
      showLevel: false,
      levelArr: [],
      levelIndex: 0,
      levelName: '',
      shichang: '',
      from: {
        id: 0,
        zhongzi: '',
        level: 0,
        shichang: '',
      }
    }
  },
  mounted() {
    console.log("mouted");
    this.from.id = this.$route.query.id ? parseInt(this.$route.query.id) : 0;
    this.init();
  },
  methods: {
    init() {
      this.getLevel();
    },
    // 获取兑换设置
    getSetZhongzi() {
      if (this.from.id > 0) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
          loadingType: 'spinner',
        });
        let that = null;
        that = this;
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/get_zhongzi_one",
            that.$qs.stringify({
              id: that.from.id
            })
          )
          .then(res => {
            Toast.clear();
            if (res.data.code == 100000) {
              that.info = res.data.data;
              that.shichang = that.info.shichang;
              that.from.shichang = that.info.shichang;
              that.from.zhongzi = that.info.zhongzi;
              that.from.level = that.info.level;
              that.levelName = that.info.levelname;
              that.levelArr.forEach((item, index) => {
                if (item.id == that.info.level) {
                  that.levelIndex = index;
                }
              })
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    // 获取等级列表
    getLevel() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/member/get_level_list")
        .then(res => {
          if (res.data.code == 100000) {
            that.levelArr = res.data.data;
          }
          that.getSetZhongzi();
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 显示等级列表
    showLevelList() {
      this.showLevel = true;
    },
    // 确认选择等级
    onConfirm(val) {
      console.log(val);
      this.levelName = val.levelname;
      this.from.level = val.id;
      this.showLevel = false;
    },
    // 取消选择等级
    onCancel(index) {
      console.log(index);
      this.showLevel = false;
    },
    // 保存设置
    onSubmit() {
      console.log(this.from);
      let that = null;
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/member/set_level_zhongzi",
          that.$qs.stringify(that.from)
        )
        .then(res => {
          Toast.clear();
          if (res.data.code == 100000) {
            Toast(res.data.msg ? res.data.msg : "设置成功");
            if (that.from.id <= 0) {
              localStorage.setItem("zhongzigengxin", 1);
            }
            setTimeout(() => {
              that.onClickLeft();
            }, 2000)
          }else {
            Toast(res.data.msg ? res.data.msg : "设置失败");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },

  }
}
</script>
<style lang="less">
.setzhongzi {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  .van-cell {
    .van-cell__value {
      flex: 1;
    }
  }
}
</style>